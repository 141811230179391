@use "sass:map";
.menu {
  &__background {
    position: absolute;
    top: 7vh;
    right: 12vh;
    width: 1px;
    height: 1px;
    content: "";
    background-color: white;
    z-index: 5;
    border: 1px solid transparent;
    border-radius: 50%;
    transform: scale(0);
    &.animate {
      animation: grow 1s forwards;
      @media (min-width: 2048px) {
        animation: growBgScreen 1s forwards;
      }
    }
    &.animate-back {
      animation: grow-reverse 1s forwards;
      @media (min-width: 2048px) {
        animation: growBgScreen-reverse 1s forwards;
      }
    }
  }
  &__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: absolute;
    transform: translate(-50%, 0);
    color: black;
    z-index: 5;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    &--single {
      height: 74px;
      @media (min-width: 1600px) {
        height: 88px;
      }
      overflow: hidden;
      a {
        display: inline-block;
        font-weight: bold;
        will-change: color;
        transition: color 0.2s;
        margin: 3em 0 0.25em 0;
        text-decoration: none;
        color: black;
        overflow: hidden;
        &:hover {
          color: map-get($theme-colors, "lightdark");
        }
      }
    }
    small {
      margin-top: 15vh;
    }
    &.animate {
      .menu__content--single:nth-child(1) {
        a {
          animation: menuSlideUp 0.75s 0.25s forwards;
        }
      }
      .menu__content--single:nth-child(2) {
        a {
          animation: menuSlideUp 1s 0.25s forwards;
        }
      }
      .menu__content--single:nth-child(3) {
        a {
          animation: menuSlideUp 1.25s 0.25s forwards;
        }
      }
    }
    &.animate-back {
      .menu__content--single:nth-child(1) {
        a {
          animation: menuSlideBack 1.25s forwards;
        }
      }
      .menu__content--single:nth-child(2) {
        a {
          animation: menuSlideBack 1s forwards;
        }
      }
      .menu__content--single:nth-child(3) {
        a {
          animation: menuSlideBack 0.75s forwards;
        }
      }
    }
  }
  &__languages {
    margin-top: 10vh;
    display: block;
    opacity: 0;
    pointer-events: none;
    &.show {
      pointer-events: all;
      display: block;
      animation: show 1s 1s forwards;
      small {
        cursor: pointer;
        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
