@use "sass:map";
body {
  overflow: hidden;
}
.main {
  position: relative;
  .main__textBlock {
    padding-top: 2vh;
  }
  &__welcome {
    p {
      line-height: 1.5;
    }
  }
  &__marketing,
  &__technology,
  &__alex {
    position: relative;
    margin-top: 10vh;
    &--header {
      margin-bottom: calc($generalPadding * 1.5);
    }
    &--text {
      overflow: hidden;
      max-height: 100vh;
      line-height: 1.5;
    }
    &--link {
      display: block;
      text-transform: uppercase;
      text-align: right;
      font-weight: bold;
      text-decoration: none;
    }
    &::after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      top: -5vh;
      left: -10vw;
      width: 100vw;
      height: 120vh;
      z-index: -1;
    }
  }
  &__marketing,
  &__technology {
    &--link {
      background-color: map-get($theme-colors, "dark");
      padding: 10px 10px;
      text-align: center;
      border: 1px solid transparent;
      border-radius: 10px;
      color: white;
      margin-top: calc($generalPadding * 1.5);
    }
  }
  &__marketing {
    &:after {
      background: linear-gradient(180deg, #1c3a4c 1%, #1bb196 100%);
      clip-path: polygon(36% 1%, 81% 0, 100% 9%, 100% 100%, 0 100%, 0 12%);
      animation: marketingPolygonFloat 11s infinite;
    }
  }
  &__technology {
    &:after {
      background: linear-gradient(180deg, #ff9c28 1%, #480d3d 100%);
      clip-path: polygon(
        20% 0,
        46% 7%,
        94% 10%,
        100% 16%,
        100% 100%,
        0 100%,
        0 8%
      );
      animation: technologyPolygonFloat 18s infinite;
    }
  }
}
