.main {
  &__polygon {
    width: 100vw;
    height: 100vh;
    will-change: clip-path;
    z-index: -1;
    clip-path: polygon(44% 0, 88% 15%, 100% 62%, 65% 100%, 29% 100%, 0 62%);
    transition: clip-path 1.5s cubic-bezier(0.5, -0.39, 0, 1.36);
    &:after {
      position: absolute;
      content: "";
      z-index: 3;
      width: 200vw;
      height: 200vh;
      margin-left: -50vw;
      margin-top: -50vh;
      background: linear-gradient(180deg, #1d374b 0%, #490a3e 100%);
      transform-origin: center;
      animation: polygonConstantMovement 12s linear infinite;
    }
  }
  & .transformationTrigger {
    position: absolute;
    color: white;
    z-index: 2;
  }
  &__welcome {
    left: 5vw;
    top: 25vh;
    @include triggerTransition(
      2,
      polygon(0 27%, 38% 16%, 84% 55%, 61% 97%, 40% 100%, 15% 86%),
      "",
      "",
      48%,
      94%,
      20%,
      23%,
      90%,
      15%
    );
  }
  &__alex {
    left: 5vw;
    top: 65vh;
    @include triggerTransition(
      3,
      polygon(0 69%, 36% 29%, 88% 50%, 76% 100%, 43% 100%, 14% 96%),
      "",
      "",
      48%,
      94%,
      20%,
      40%,
      0,
      2%
    );

    &:after {
      top: -65vh;
      left: -25vw;
      background: radial-gradient(circle, #23c9ff 0%, #092a34 100%);
      clip-path: polygon(86% 0, 100% 38%, 76% 100%, 0 100%, 0 0);
    }
    & > .ball {
      display: none;
      position: absolute;
      left: 80vw;
      bottom: 12vh;
      width: 13vh;
      transform: rotate(341deg);
    }
    &:hover {
      & > .ball {
        display: block;
      }
    }
  }
  &__marketing {
    right: 5vw;
    top: 25vh;
    @include triggerTransition(
      4,
      polygon(16% 18%, 98% 9%, 91% 60%, 39% 100%, 23% 100%, 0 66%),
      "",
      "",
      65%,
      0,
      25%,
      44%,
      0,
      2%
    );

    &:after {
      top: -25vh;
      right: -25vw;
      background: radial-gradient(circle, #1bb196 0%, #1c3a4c 100%);
      clip-path: polygon(61% 2%, 100% 31%, 90% 84%, 40% 94%, 4% 60%, 15% 15%);
    }
  }
  &__technology {
    right: 5vw;
    top: 65vh;
    @include triggerTransition(
      5,
      polygon(18% 11%, 73% 32%, 100% 89%, 64% 100%, 29% 100%, 10% 81%),
      "",
      "",
      40%,
      5%,
      25%,
      38%,
      27%,
      3%,
      45deg
    );
    &:after {
      top: -65vh;
      left: -25vw;
      background: radial-gradient(circle, #ff9c28 0%, #480d3d 100%);
      clip-path: polygon(23% 16%, 90% 0, 100% 0, 100% 100%, 25% 100%, 0 81%);
    }
  }
  &__image {
    position: absolute;
    pointer-events: none;
    aspect-ratio: 16 / 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 5;
    will-change: opacity;
    &[data-img="1"] {
      opacity: 1;
      background-image: url("./assets/tree.png");
      animation: tremble 1s linear infinite;
    }
    &[data-img="2"] {
      background-image: url("./assets/hello.png");
      height: 30vh;
    }

    &[data-img="4"] {
      background-image: url("./assets/cash.png");
      height: 50vh;
    }
    &[data-img="5"] {
      background-image: url("./assets/technology.png");
    }
  }

  &__textBlock {
    cursor: pointer;
    width: 25vw;
    h1,
    h2 {
      margin-top: 0;
      font-weight: bold;
      margin-bottom: 0;
      text-align: right;
      &.main__alex--header,
      &.main__welcome--header {
        text-align: left;
      }
    }
    &.main__alex,
    &.main__welcome {
      cursor: default;
    }
    p {
      font-weight: 200;
      margin-top: 0;
      height: 0;
      top: 0;
      opacity: 0;
      overflow: hidden;
      will-change: opacity;
      transition: opacity 0.5s 0.1s;
      &.welcome--alwaysVisible {
        height: auto;
        opacity: 1;
      }
      &.main__technology--link,
      &.main__marketing--link,
      &.main__alex--link {
        opacity: 1;
        height: auto;
        font-weight: bold;
        will-change: color;
        transition: color 0.2s;
        text-transform: uppercase;
      }
      &.main__technology--link {
        color: map-get($theme-colors, "orange");
        text-align: right;
      }
      &.main__marketing--link {
        color: map-get($theme-colors, "green");
        text-align: right;
      }
      &.main__alex--link {
        color: map-get($theme-colors, "lightBlue");
        text-align: left;
        margin: 5px 0;
      }
    }
    .move-up {
      position: absolute;
      top: 0;
      will-change: top;
    }
  }
}
