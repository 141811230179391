@use "sass:map";

$generalPadding: 10%;

$theme-colors: (
  "dark": #303030,
  "lightdark": #7e7e7f,
  "purple": #490a3e,
  "orange": #ff9c28,
  "green": #06b196,
  "navy": #1d374b,
  "lightBlue": #23c9ff,
);

$font-sizes-fhd: (
  "small": 20px,
  "regular": 28px,
  "bigger": 38px,
  "big": 72px,
);
$font-sizes-hd: (
  "small": 18px,
  "regular": 24px,
  "bigger": 34px,
  "big": 60px,
);
$font-sizes: (
  "small": 16px,
  "regular": 20px,
  "bigger": 28px,
  "big": 48px,
);
$font-mob-sizes: (
  "small": 16px,
  "regular": 20px,
  "bigger": 28px,
  "big": 40px,
);

.f-mob-small {
  font-size: map-get($font-mob-sizes, "small");
}
.f-mob-regular {
  font-size: map-get($font-mob-sizes, "regular");
}
.f-mob-bigger {
  font-size: map-get($font-mob-sizes, "bigger");
}
.f-mob-big {
  font-size: map-get($font-mob-sizes, "big");
}
.f-small {
  font-size: map-get($font-sizes, "small");
}
.f-regular {
  font-size: map-get($font-sizes, "regular");
}
.f-bigger {
  font-size: map-get($font-sizes, "bigger");
}
.f-big {
  font-size: map-get($font-sizes, "big");
}
@media (min-width: 1600px) {
  .f-small {
    font-size: map-get($font-sizes-hd, "small");
  }
  .f-regular {
    font-size: map-get($font-sizes-hd, "regular");
  }
  .f-bigger {
    font-size: map-get($font-sizes-hd, "bigger");
  }
  .f-big {
    font-size: map-get($font-sizes-hd, "big");
  }
}
@media (min-width: 1920px) {
  .f-small {
    font-size: map-get($font-sizes-fhd, "small");
  }
  .f-regular {
    font-size: map-get($font-sizes-fhd, "regular");
  }
  .f-bigger {
    font-size: map-get($font-sizes-fhd, "bigger");
  }
  .f-big {
    font-size: map-get($font-sizes-fhd, "big");
  }
}
