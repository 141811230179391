@keyframes show {
  to {
    opacity: 1;
  }
}
@keyframes grow {
  to {
    transform: scale(1000);
  }
}
@keyframes grow-reverse {
  to {
    transform: scale(0);
  }
}
@keyframes menuSlideIn {
  0% {
    transform: translateX(0);
    color: white;
    width: 0;
  }
  10% {
    transform: translateX(0);
    color: white;
    width: 100vw;
  }
  100% {
    transform: translateX(-100vw);
    color: black;
    width: 100vw;
  }
}
@keyframes menuSlideBack {
  0% {
    transform: translateX(-100vw);
    color: black;
    width: 100vw;
  }
  30% {
    transform: translateX(-100vw);
    color: white;
    width: 100vw;
  }
  100% {
    transform: translateX(0);
    color: white;
    width: 0;
  }
}

@keyframes welcomePolygonFloat {
  0% {
    clip-path: polygon(50% 1%, 75% 0%, 100% 10%, 100% 100%, 0 100%, 0 7%);
  }
  50% {
    clip-path: polygon(41% 0, 68% 4%, 100% 0, 100% 100%, 0 100%, 0 4%);
  }
  100% {
    clip-path: polygon(50% 1%, 75% 0%, 100% 10%, 100% 100%, 0 100%, 0 7%);
  }
}
@keyframes marketingPolygonFloat {
  0% {
    clip-path: polygon(36% 1%, 81% 0, 100% 9%, 100% 100%, 0 100%, 0 12%);
  }
  50% {
    clip-path: polygon(41% 0, 68% 4%, 100% 0, 100% 100%, 0 100%, 0 4%);
  }
  100% {
    clip-path: polygon(36% 1%, 81% 0, 100% 9%, 100% 100%, 0 100%, 0 12%);
  }
}
@keyframes technologyPolygonFloat {
  0% {
    clip-path: polygon(
      20% 0,
      46% 7%,
      94% 10%,
      100% 16%,
      100% 100%,
      0 100%,
      0 8%
    );
  }
  50% {
    clip-path: polygon(
      15% 0,
      65% 8%,
      86% 3%,
      100% 7%,
      100% 100%,
      0 100%,
      0 15%
    );
  }
  100% {
    clip-path: polygon(
      20% 0,
      46% 7%,
      94% 10%,
      100% 16%,
      100% 100%,
      0 100%,
      0 8%
    );
  }
}
@keyframes alexPolygonFloat {
  0% {
    clip-path: polygon(
      12% 5%,
      20% 0,
      75% 2%,
      100% 7%,
      100% 100%,
      0 100%,
      0 10%
    );
  }
  50% {
    clip-path: polygon(
      10% 2%,
      31% 5%,
      60% 1%,
      100% 6%,
      100% 100%,
      0 100%,
      0 9%
    );
  }
  100% {
    clip-path: polygon(
      12% 5%,
      20% 0,
      75% 2%,
      100% 7%,
      100% 100%,
      0 100%,
      0 10%
    );
  }
}
@keyframes tremble {
  0% {
    margin: 0 0 0 0;
  }
  20% {
    margin: 1px 0 0 0;
  }
  40% {
    margin: 0 1px 2px 0;
  }
  60% {
    margin: 0 0 2px 2px;
  }
  80% {
    margin: 0 0 0 1px;
  }
  100% {
    margin: 0 0 0 0;
  }
}
@keyframes tremble2 {
  0% {
    margin: 0 0 0 0;
  }
  20% {
    margin: 0 0 0 1px;
  }
  40% {
    margin: 0 0 2px 2px;
  }
  60% {
    margin: 0 2px 2px 0;
  }
  80% {
    margin: 2px 2px 0 0;
  }
  100% {
    margin: 0 0 0 0;
  }
}
