@use "sass:map";
@mixin triggerTransition(
  $elToShowNrFromDataSet,
  $clipPathForMainPolygon,
  $svgBeforeURL,
  $svgAfterURL,
  $beforePositionX,
  $beforePositionY,
  $beforeSize,
  $afterPositionX,
  $afterPositionY,
  $afterSize,
  $beforeRotation: 0deg,
  $afterRotation: 0deg
) {
  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    opacity: 0;
    will-change: opacity;
    transition: all 0.25s;
  }
  &:hover {
    z-index: 5;
    & ~ .main__image[data-img="1"] {
      opacity: 0;
    }
    & ~ .main__image[data-img="#{$elToShowNrFromDataSet}"] {
      opacity: 1;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(#{$svgBeforeURL});
        background-repeat: no-repeat;
        background-position-x: #{$beforePositionX};
        background-position-y: #{$beforePositionY};
        background-size: #{$beforeSize};
        position: absolute;
        left: 0;
        top: 0;
        transform: rotate(#{$beforeRotation});
        animation: tremble 0.5s linear infinite;
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(#{$svgAfterURL});
        background-repeat: no-repeat;
        background-position-x: #{$afterPositionX};
        background-position-y: #{$afterPositionY};
        background-size: #{$afterSize};
        position: absolute;
        left: 0;
        top: 0;
        animation: tremble2 0.45s linear infinite;
      }
    }
    & ~ .main__polygon {
      clip-path: #{$clipPathForMainPolygon};
    }
    p {
      height: 50vh;
      opacity: 1;
      &.main__technology--link,
      &.main__marketing--link {
        height: auto;
      }
      &.main__alex--link {
        display: none;
      }

      &.main__technology {
        &--link {
          color: map.get($theme-colors, "purple");
        }
        &--text {
          color: map.get($theme-colors, "purple");
        }
      }
      &.main__marketing {
        &--link {
          color: map.get($theme-colors, "navy");
        }
        &--text {
          color: map.get($theme-colors, "navy");
        }
      }
    }
    h1 {
      &.main__technology--header {
        color: map.get($theme-colors, "purple");
      }
      &.main__marketing--header {
        color: map.get($theme-colors, "navy");
      }
    }
    h2 {
      &.main__alex--header.f-regular {
        font-size: 28px;
        @media (min-width: 1600px) {
          font-size: 34px;
        }
        @media (min-width: 1920px) {
          font-size: 38px;
        }
      }
    }
    .move-up {
      top: -40vh;
    }
    &:after {
      opacity: 1;
      width: 70vw;
      height: 100vh;
      pointer-events: none;
    }
  }
}
