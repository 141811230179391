// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap");
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  font-family: "Inter", sans-serif;
  background: linear-gradient(
    0deg,
    rgba(29, 55, 75, 1) 0%,
    rgba(73, 10, 62, 1) 100%
  );
}
