// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap");
html {
  overflow: auto;
}
body {
  .forceOrientationInfo {
    display: none;
  }
  @media (orientation: landscape) and (max-width: 1023px) and (any-hover: none) {
    overflow: hidden;
    .forceOrientationInfo {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: linear-gradient(
        90deg,
        rgba(73, 10, 62, 1) 0,
        rgba(29, 55, 75, 1) 100%
      );
      z-index: 10;
      &__inner {
        margin: auto;
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    }
  }
  margin: 0;
  padding: calc($generalPadding/2) $generalPadding;
  padding-bottom: 0;
  width: calc(100vw - (2 * $generalPadding));
  height: auto;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  font-family: "Inter", sans-serif;
  color: white;
  background: linear-gradient(
    0deg,
    rgba(29, 55, 75, 1) 80%,
    rgba(73, 10, 62, 1) 100%
  );
  &.no-scroll {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
}
