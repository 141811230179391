@use "sass:map";
.menu {
  width: 0;
  overflow: hidden;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  left: 100vw;
  color: white;
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    &--single {
      height: 74px;
      @media (min-width: 1600px) {
        height: 88px;
      }
      overflow: hidden;
      a {
        display: inline-block;
        font-weight: bold;
        will-change: color;
        transition: color 0.2s;
        text-decoration: none;
        color: inherit;
        overflow: hidden;
      }
    }
  }
  &__languages {
    font-size: larger;
    margin-top: 10vh;
    display: block;
    opacity: 0;
    pointer-events: none;
    &.show {
      pointer-events: all;
      display: block;
      animation: show 1s 1s forwards;
      small {
        &.active {
          font-weight: bold;
        }
      }
    }
  }
  &.animate {
    animation: menuSlideIn 0.5s forwards;
  }
  &.animate-back {
    animation: menuSlideBack 0.5s forwards;
  }
}
