@use "sass:map";
.footer {
  margin-top: 10vh;
  width: 80vw;
  &__inner {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    &-left {
      display: flex;
      flex-flow: column;
      cursor: copy;
      a {
        text-decoration: none;
        color: white;
      }
    }
    &-right {
      a {
        display: block;
        height: 6vh;
        width: 6vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("./assets/svg/LinkedIN_white.svg");
      }
    }
  }
}
