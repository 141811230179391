@use "sass:map";
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  flex-flow: row;
  justify-content: center;
  z-index: 5;
  &__inner {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    color: white;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    &-left {
      display: flex;
      flex-flow: column;
      cursor: copy;
    }
    &-right {
      a {
        display: block;
        height: 3vh;
        width: 3vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("./assets/svg/LinkedIN_white.svg");
        cursor: pointer;
        &:hover {
          background-image: url("./assets/svg/LinkedIN_dark.svg");
        }
      }
    }
  }
}
