@import "./styles/utils";

html {
  overflow: auto;
}

@media (min-width: 1024px) {
  @import "./styles/mixins";
  @import "./styles/animations";
  @import "./styles/general";
  @import "./styles/header";
  @import "./styles/main";
  @import "./styles/menu";
  @import "./styles/footer";
}
@media (max-width: 1023px) {
  @import "./styles/mobile/animations";
  @import "./styles/mobile/general";
  @import "./styles/mobile/header";
  @import "./styles/mobile/menu";
  @import "./styles/mobile/main";
  @import "./styles/mobile/footer";
}
