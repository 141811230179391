.header {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 90vw;
  padding: 0 5vw;
  height: 15vh;
  z-index: 6;
  justify-content: space-between;
  align-items: center;
  &__logo {
    height: 15vh;
    width: 15vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/dna_logo/dna_logo-white.svg");
  }
  &__menuBtn {
    height: 5vh;
    width: 5vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/burger/burger_white.svg");
    cursor: pointer;
    &:hover {
      background-image: url("./assets/burger/burger_dark.svg");
    }
  }
}
.header.menuOpen {
  z-index: 6;
  .header__logo {
    background-image: url("./assets/dna_logo/dna-logo-dark.svg");
  }
  .header__menuBtn {
    background-image: url("./assets/burger/x-close_white.svg");
    transition: transform 0.2s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}
