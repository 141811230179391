@keyframes polygonConstantMovement {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes tremble {
  0% {
    margin: 0 0 0 0;
  }
  20% {
    margin: 3px 0 0 0;
  }
  40% {
    margin: 0 3px 3px 0;
  }
  60% {
    margin: 0 0 3px 3px;
  }
  80% {
    margin: 0 0 0 3px;
  }
  100% {
    margin: 0 0 0 0;
  }
}
@keyframes tremble2 {
  0% {
    margin: 0 0 0 0;
  }
  20% {
    margin: 0 0 0 1px;
  }
  40% {
    margin: 0 0 3px 3px;
  }
  60% {
    margin: 0 3px 3px 0;
  }
  80% {
    margin: 3px 2px 0 0;
  }
  100% {
    margin: 0 0 0 0;
  }
}
@keyframes grow {
  to {
    transform: scale(1500);
  }
}
@keyframes grow-reverse {
  from {
    transform: scale(1500);
  }
  to {
    transform: scale(0);
  }
}
@keyframes growBgScreen {
  to {
    transform: scale(2500);
  }
}
@keyframes growBgScreen-reverse {
  from {
    transform: scale(2500);
  }
  to {
    transform: scale(0);
  }
}
@keyframes menuSlideUp {
  to {
    transform: translateY(-3em);
  }
}
@keyframes menuSlideBack {
  from {
    transform: translateY(-3em);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes show {
  to {
    opacity: 1;
  }
}
