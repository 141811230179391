.header {
  display: flex;
  width: 100%;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  &__logo {
    height: 100%;
    width: 30%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/dna_logo/dna_logo-white.svg");
    z-index: 6;
  }
  &__menuBtn {
    height: 6vh;
    width: 6vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/burger/burger_white.svg");
    z-index: 6;
  }
}
.header.menuOpen {
  z-index: 6;
  .header__logo {
    background-image: url("./assets/dna_logo/dna-logo-dark.svg");
  }
  .header__menuBtn {
    background-image: url("./assets/burger/x-close_white.svg");
  }
}
